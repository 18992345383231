import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select";
import { mobile } from "clutch/src/Style/style.mjs";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { readState } from "@/__main__/app-state.mjs";
import {
  appURLs,
  FALLBACK_IMAGE_URL,
  MIN_STRING_DISTANCE,
} from "@/app/constants.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox.jsx";
import { ItemBox, ItemContainer } from "@/game-fortnite/components/ItemBox.jsx";
import WeaponsStatsTable from "@/game-fortnite/components/WeaponsStatsTable";
import Rarities, {
  RARITY_SYMBOLS,
} from "@/game-fortnite/constants/rarities.mjs";
import type { WeaponCategory } from "@/game-fortnite/constants/weaponCategories.mjs";
import { categories } from "@/game-fortnite/constants/weaponCategories.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import type { WeaponItem } from "@/game-fortnite/models/items.mjs";
import {
  getCritDamage,
  getDamage,
  getDamagePerSecond,
  getEnvDamage,
} from "@/game-fortnite/utils/weapon-stats.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { WikiCard, WikiLayout, WikiSidebar } from "@/shared/Wiki.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const headerClass = () => css`
  .header-image--img {
    transform: none !important;
  }

  .header-image--inner {
    &::after {
      box-shadow: none !important;
      border: none !important;
    }
  }
`;

const sidebarCss = () => css`
  section:first-child {
    width: initial;
    margin: var(--sp-6);
    background: hsla(222, 16%, 11%, 1);

    img {
      width: var(--sp-30);
      height: var(--sp-30);
      margin: var(--sp-2_5) auto;
    }
  }
`;

const searchContainer = () => css`
  display: flex;
  flex-direction: row;
  gap: var(--sp-2);

  ${mobile} {
    flex-direction: column;

    .selectButton {
      width: 100%;
    }
  }
`;

const rarityOptions = Object.getOwnPropertySymbols(Rarities).map((r) => {
  return {
    value: Rarities[r].key,
    text: Rarities[r].t,
    icon:
      Rarities[r].key !== "all" ? (
        <span
          style={{
            width: "var(--sp-2)",
            height: "var(--sp-2)",
            borderRadius: "var(--sp-0_5)",
            background: `hsla(${Rarities[r].color}, 1)`,
            alignSelf: "center",
          }}
        />
      ) : null,
  };
});

function findWeapon(
  weaponId,
  active,
  vaulted,
): { category?: WeaponCategory; weapon?: WeaponItem } {
  for (const category of categories) {
    const weapon =
      active[category.key]?.[weaponId] ?? vaulted[category.key]?.[weaponId];
    if (weapon) return { category, weapon };
  }
  return {};
}

function WeaponInfoSidebar({
  category,
  weapon,
}: {
  category: WeaponCategory;
  weapon: WeaponItem;
}) {
  const { t } = useTranslation();
  const multiplier = "x";
  const rarities = Object.keys(weapon.stats)
    .map(
      (r) =>
        Rarities[
          Object.getOwnPropertySymbols(Rarities).find(
            (s) => Rarities[s].key === r.toLowerCase(),
          )
        ],
    )
    .filter((r) => r)
    .sort((a, b) => a.rank - b.rank);
  const firstRarityStats = Object.values(weapon.stats)[0];
  return (
    <WikiSidebar
      imgSrc={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
      itemName={
        [
          "fortnite:database.weaponName",
          "{{weaponName}}",
          { weaponName: weapon.displayName },
        ] as Translation
      }
      className={sidebarCss()}
    >
      <WikiSidebar.Section>
        <dl>
          <dt>{t("fortnite:rarity", "Rarity")}</dt>
          <dd className="flex wrap justify-end">
            {rarities.map((r, index) => (
              <span key={r.key} style={{ color: `hsla(${r.color}, 1)` }}>
                {t(...r.t)}
                {index !== rarities.length - 1 ? (
                  <span className="shade0">&nbsp;/&nbsp;</span>
                ) : null}
              </span>
            ))}
          </dd>
          <dt>{t("fortnite:weaponType", "Type")}</dt>
          <dd>
            {t(
              ...(category
                ? category.t
                : ["fortnite:weaponCategories.other", "Other"]),
            )}
          </dd>
          {/*<dt>{t("fortnite:ammo", "Ammo")}</dt>*/}
          {/*<dd>{weapon.ammo}</dd>*/}
        </dl>
      </WikiSidebar.Section>
      <WikiSidebar.Section>
        <WikiSidebar.Header>
          {t("fortnite:statistics", "Statistics")}
        </WikiSidebar.Header>
        <dl>
          <dt>{t("fortnite:stats.fireRate", "Fire Rate")}</dt>
          <dd>{firstRarityStats.firingRate}</dd>
          <dt>{t("fortnite:stats.magazineSize", "Magazine Size")}</dt>
          <dd>{firstRarityStats.clipSize}</dd>
          <dt>
            {t(
              "fortnite:stats.critDamageMultiplier",
              "Crit. Damage Multiplier",
            )}
          </dt>
          <dd>
            {multiplier}
            {firstRarityStats.criticalDamageMultiplier}
          </dd>
        </dl>
      </WikiSidebar.Section>
    </WikiSidebar>
  );
}

function DatabaseWeapon() {
  const { t } = useTranslation();
  const {
    parameters: [weaponId],
  } = useRoute();
  const {
    fortnite: { active, vaulted },
  } = useSnapshot(readState);
  const { category, weapon } = findWeapon(weaponId, active, vaulted);

  const variantWeapons = useMemo(() => {
    if (!weapon || !weapon.stats) return;
    return Object.entries(weapon.stats).map(([rarity, stats]) => {
      return [
        {
          display: (
            <ItemContainer>
              <ItemBox
                id={weaponId}
                name={weapon.displayName}
                rarity={rarity.toUpperCase() as Rarity}
                src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
              />
              <p className="type-callout--bold shade0 name">{rarity}</p>
            </ItemContainer>
          ),
          value: rarity,
        },
        {
          display: getLocaleString(getDamagePerSecond(stats)),
          value: getDamagePerSecond(stats),
        },
        {
          display: getLocaleString(getDamage(stats)),
          value: getDamage(stats),
        },
        {
          display: getLocaleString(getEnvDamage(stats)),
          value: getEnvDamage(stats),
        },
        {
          display: getLocaleString(getCritDamage(stats)),
          value: getCritDamage(stats),
        },
        {
          display: getLocaleString(stats.reloadTime),
          value: stats.reloadTime,
        },
        {
          display: getLocaleString(stats.firingRate),
          value: stats.firingRate,
        },
        {
          display: getLocaleString(stats.clipSize),
          value: stats.clipSize,
        },
      ];
    });
  }, [weaponId, weapon]);

  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [rarityFilter, setRarityFilter] = useQuery<string>(
    "rarity",
    Rarities[RARITY_SYMBOLS.fortniteAllRarities].key,
  );
  const raritySymbol = Object.getOwnPropertySymbols(Rarities).find(
    (r) => Rarities[r].key === rarityFilter,
  );
  const categoryWeapons = Object.values({
    ...(vaulted[category?.key] || {}),
    ...(active[category?.key] || {}),
  }).filter((v) => {
    if (nameFilter) {
      return stringCompare(v.displayName, nameFilter) > MIN_STRING_DISTANCE;
    }
    return true;
  }) as WeaponItem[];

  if (!active || !vaulted) {
    return (
      <WikiLayout
        title={["fortnite:weapon", "Weapon"]}
        imgSrc={FALLBACK_IMAGE_URL}
        gameSymbol={GAME_SYMBOL_FORTNITE}
        containerClassName={headerClass()}
      >
        <div className="sidebar">
          <WikiCard loading style={{ height: "1500px" }} />
        </div>
        <div className="main">
          <WikiCard loading style={{ height: "1500px" }} />
          <WikiCard loading style={{ height: "128px" }} />
        </div>
      </WikiLayout>
    );
  }

  if (!weapon) return null;

  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_FORTNITE}
      title={
        [
          "fortnite:database.weaponName",
          "{{weaponName}}",
          { weaponName: weapon.displayName },
        ] as Translation
      }
      imgSrc={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
      containerClassName={headerClass()}
    >
      <div className="sidebar">
        <WeaponInfoSidebar category={category} weapon={weapon as WeaponItem} />
      </div>
      <div className="main">
        <DataTable
          className="wiki-table"
          indexCol
          indexColTitle={"#"}
          sortCol={1}
          cols={[
            {
              display: t("fortnite:rarity", "Rarity"),
              primary: true,
              align: "left",
            },
            { display: t("fortnite:stats.dps", "DPS"), isStat: true },
            { display: t("fortnite:stats.damage", "Damage"), isStat: true },
            {
              display: t("fortnite:stats.structDamage", "Struct. Damage"),
              isStat: true,
            },
            {
              display: t("fortnite:stats.critDamage", "Crit. Damage"),
              isStat: true,
            },
            {
              display: t("fortnite.stats.reloadTime", "Reload Time"),
              isStat: true,
            },
            {
              display: t("fortnite.stats.rateOfFire", "Rate Of Fire"),
              isStat: true,
            },
            {
              display: t("fortnite.stats.magSize", "Mag. Size"),
              isStat: true,
            },
          ]}
          rows={variantWeapons}
        />
        <div className={searchContainer()}>
          <TextInput
            placeholder={t("common:search", "Search")}
            onChange={(e) => setNameFilter(e.target.value)}
            value={nameFilter}
          />
          <Select
            options={rarityOptions}
            selected={rarityFilter || ""}
            onChange={(v) => setRarityFilter(v || undefined)}
          />
        </div>
        <WeaponsStatsTable
          weapons={categoryWeapons}
          rarity={raritySymbol}
          className="wiki-table"
        />
      </div>
    </WikiLayout>
  );
}

export default DatabaseWeapon;

export function meta([weaponKey]) {
  const {
    fortnite: { active, vaulted },
  } = readState;
  const { weapon } = findWeapon(weaponKey, active, vaulted);
  return {
    title: weapon
      ? ([
          "fortnite:meta.database.weapon.name",
          "{{weaponName}} - Fortnite weapon",
          { weaponName: weapon.displayName },
        ] as Translation)
      : ["fortnite:meta.database.weapon.name.default", "Weapon"],
    description: weapon
      ? [
          `fortnite:meta.database.weapon.description.${weaponKey}`,
          weapon.description,
        ]
      : [
          "fortnite:meta.database.weapon.description.default",
          "Fortnite weapon description",
        ],
    subtitle: true,
  };
}
